<template>
  <header class="header" id="header">
    <nav class="navbar bg-light navbar-expand fixed-bottom">
      <ul class="navbar-nav nav-justified w-100">
        <li class="nav-item">
          <a href="#home"
             onclick="PaginaInicial_OnClick()"
             class="nav-link menu_bottom_text text-center">
            <i class="ph-house nav__icon"></i>
            <span class="small d-block">Início</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#"
             onclick="VerProcedimentos_OnClick()"
             class="nav-link menu_bottom_text text-center">
            <i class="ph-note nav__icon"></i>
            <span class="small d-block">Procedimentos</span>
          </a>
        </li>

        <li class="nav-item">
          <a href="#about" class="nav-link menu_bottom_text text-center">
            <i class="ph-currency-circle-dollar nav__icon"></i>
            <span class="small d-block">Financeiro</span>
          </a>
        </li>

        <li class="nav-item">
          <a href="#skills" class="nav-link menu_bottom_text text-center">
            <i class="ph-folder nav__icon"></i>
            <span class="small d-block">Docs</span> </a>
        </li>
        <li class="nav-item dropdown">
          <div class="dropup">
            <a href="#" class="nav-link menu_bottom_text text-center "
               role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ph-list nav__icon"></i>
              <span class="small d-block">Menu</span>
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Configurações</a></li>
              <li><a class="dropdown-item" href="#">SYS ADMIN</a></li>
              <li><a class="dropdown-item" href="#">EASY CALCS</a></li>
              <li><a class="dropdown-item" href="#">Ajuda</a></li>
              <li><a class="dropdown-item" href="#">Logout</a></li>

            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </header>
</template>
<style scoped>
.menu_bottom_text {
  color: grey;
  font-weight: 600;
}

.nav__icon {

  font-size: 1.6rem;

}

.navbar-nav .nav-link.active{
  color:#0e90d2;
}

@media(min-width: 800px) {
  .navbar {
    /*position:fixed; top:0; left:0; z-index:1030;*/
    max-width: 500px;
    margin: auto;
  }
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
  left: -90px;
}


</style>

<script itemscope>

import i18n from "../i18n";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      languages: [
          {
        flag: require("@/assets/images/flags/us.svg"),
        language: "en",
        title: "English",
      },
        {
          flag: require("@/assets/images/flags/french.svg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.svg"),
          language: "sp",
          title: "Spanish",
        },
        {
          flag: require("@/assets/images/flags/china.svg"),
          language: "ch",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/germany.svg"),
          language: "gr",
          title: "Deutsche",
        },
        {
          flag: require("@/assets/images/flags/russia.svg"),
          language: "ru",
          title: "русский",
        },
        {
          flag: require("@/assets/images/flags/russia.svg"),
          language: "br",
          title: "Português",
        },
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,
      usuario: {
        nome : ''
      },
      PhotoLocal: localStorage.getItem('user-Photo'),
      URL : process.env.VUE_APP_API
    };
  },
  components: {
  },
  methods: {

    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      searchInput.addEventListener("focus", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchInput.addEventListener("keyup", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchOptions.addEventListener("click", () => {
        searchInput.value = "";
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
    },
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
          document.documentElement.getAttribute("data-layout") === "horizontal"
      ) {
        document.body.classList.contains("menu") ?
            document.body.classList.remove("menu") :
            document.body.classList.add("menu");
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
              document.documentElement.setAttribute("data-sidebar-size", "") :
              document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
              document.documentElement.setAttribute("data-sidebar-size", "sm") :
              document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          document.body.classList.add("vertical-sidebar-enable");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      }

      //Two column menu
      if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
        document.body.classList.contains("twocolumn-panel") ?
            document.body.classList.remove("twocolumn-panel") :
            document.body.classList.add("twocolumn-panel");
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
          !document.fullscreenElement &&
          /* alternative standard method */
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
    },
    toggleDarkMode() {
      if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
        document.documentElement.setAttribute("data-layout-mode", "light");
      } else {
        document.documentElement.setAttribute("data-layout-mode", "dark");
      }
    },
  },
  computed: {},
  mounted() {
    try {
      document.addEventListener("scroll", function () {
        var pageTopbar = document.getElementById("page-topbar");
        if (pageTopbar) {
          document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
              "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
        }
      });
      if (document.getElementById("topnav-hamburger-icon"))
        document
            .getElementById("topnav-hamburger-icon")
            .addEventListener("click", this.toggleHamburgerMenu);

      this.isCustomDropdown();

      //Data bind usuario

      const usuario =  JSON.parse(localStorage.getItem('userdata'));
      console.log(usuario);

      this.usuario.nome = usuario.Nome;



    }catch (e){
      console.log(e);
    }
  },
};
</script>